@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Inter"), url(./fonts/InterRegular.ttf) format("truetype");
  }
  @font-face {
    font-family: "InterBold";
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: local("Inter"), url(./fonts/Inter-Bold.ttf) format("truetype");
  }

  @font-face {
    font-family: "InterBlack";
    font-display: swap;
    src: local("Inter"), url(./fonts/Inter-Black.ttf) format("truetype");
  }

  @font-face {
    font-family: "InterLight";
    font-display: swap;
    src: local("Inter"), url(./fonts/Inter-Light.ttf) format("truetype");
  }

  @font-face {
    font-family: "PolySans";
    font-display: swap;
    src: local("PolySans"), url(./fonts/PolySansGX.ttf) format("truetype");
  }
  @font-face {
    font-family: "PolySansItalic";
    font-display: swap;
    src: local("PolySansItalic"),
      url(./fonts/PolySansItalicGX.ttf) format("truetype");
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.clip-path-curve-bottom {
  clip-path: path("M 0 0 Q 50% 100%, 100% 0 V 100 H 0 Z");
}
